@import '~@uopx/styles/dist/sass/variables';
@import '~@uopx/styles/dist/sass/mixins';
//@import '~@uopx/styles/dist/sass/cssBaseline';
@import '~@uopx/styles/dist/sass/utilities/accessibility';
$input-error-border-color:#B21F13;
$error-text-color:#8A1500;
.uopx-input {
  &.MuiTextField-root {
    margin-bottom: 24px;

    .MuiFormLabel-root {
      font-size: 1.6rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      height: 24px;
      letter-spacing: 0.00938em;

      &.Mui-error {
        color: $error-text-color;
        [data-testid="CloseIcon"]{
          display:none;
        }
      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(16px, 4px) scale(0.75);
    }

    .MuiInputBase-root {
      background-color: #fff;
      border-color: #000;
      font-size: 1.6rem;
      border-radius: 3px;
      height: 56px;

      fieldset legend {
        display: none;
      }

      &.Mui-focused fieldset {
        border:1px solid #001823;
      }
    }

    //label.Mui-focused.MuiInputLabel-root,
    //label.MuiFormLabel-filled {
    //  color: $input-focus-label-color;
    //}

    // Invalid
    .Mui-error {
      &.MuiOutlinedInput-root {
        border-radius: 4px 4px 0 0;

        &.Mui-focused fieldset {
          border: 1px solid;
          border-color: $input-error-border-color !important;

          &.MuiOutlinedInput-notchedOutline {
            border:1px solid #001823;
            border-radius: 4px 4px 0 0;
          }
        }
      }

      label.Mui-focused {
        color: $primary-color;
      }

      .MuiFormHelperText-contained {
        border-color: $input-error-border-color;
        color: $error-text-color;
        border-style: solid;
        border-width: 1px;
        border-top-width: inherit;
        margin: 0;
        padding: 0.7rem 1.4rem;
        font-size: 12px;
        border-radius: 0 0 4px 4px;
      }
    }
  }

  .MuiInputLabel-root {
    &.MuiFormLabel-filled {
      //  color: $input-label-color;
      padding-top: 4px;
      padding-bottom: 2px;
    }
  }
}

.uopx-textarea {
  &.uopx-input.MuiTextField-root .MuiInputBase-root {
    height: auto;
  }

  textarea {
    padding: 0;
  }
}

.uopx-textarea .Mui-error.MuiFormHelperText-root {
  border-color: $input-error-border-color;
  color: $error-text-color;
  border-style: solid;
  border-width: 1px;
  border-top-width: inherit;
  margin: 0;
  padding: 0.7rem 1.4rem;
  font-size: 12px;
  border-radius: 0 0 4px 4px;
}

.mobile-form .MuiFormGroup-root.MuiFormGroup-row {
  margin-bottom: 0;
}

.input-time-picker {
  position: relative;
  & input[type=time]::-webkit-calendar-picker-indicator {
    width: 28px;
    height: 28px;
    margin-right: -5px;
    margin-top: -6px;
  }
}