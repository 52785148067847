@import '~@uopx/styles/dist/sass/variables';
@import '~@uopx/styles/dist/sass/mixins';
//@import '~@uopx/styles/dist/sass/cssBaseline';
@import '~@uopx/styles/dist/sass/utilities/accessibility';

.uopx-checkbox {
  &.MuiFormControl-root {
    .MuiFormGroup-root {
      .MuiButtonBase-root {
        &:hover {
          background-color: transparent;
        }
      }

      &:not(.MuiFormGroup-row) {
        align-items: baseline;
      }
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: #000;

      &:hover {
        background-color: transparent;
      }
    }

    .MuiTouchRipple-root {
      background-color: transparent;
    }
  }
  // SVG icon
  .MuiIconButton-label .MuiSvgIcon-root {
    fill: #000;
    width: 2rem;
    height: 2rem;
  }
}
// base10
.base10 .uopx-checkbox {
  .MuiFormLabel-root,
  .MuiTypography-root {
    font-size: 1.6rem;
  }
  .MuiSvgIcon-root {
    width: 3rem;
    height: 3rem;
  }
}