@import '~@uopx/styles/dist/sass/variables';
@import '~@uopx/styles/dist/sass/mixins';
//@import '~@uopx/styles/dist/sass/cssBaseline';
@import '~@uopx/styles/dist/sass/utilities/accessibility';
$input-error-border-color:#B21F13;
$error-text-color:#8A1500;
.uopx-input {
  &.MuiTextField-root {
    margin-bottom: 2rem;

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, 4px) scale(0.75);
    }

    .MuiInputBase-root {
      background-color: #fff;
      border-radius: 4px;
      border-color: #000;

      fieldset legend {
        display: none;
      }

      &.Mui-focused fieldset {
        border:1px solid #001823;
      }
    }

    label.Mui-focused.MuiInputLabel-root,
    label.MuiFormLabel-filled {
      color: $input-focus-label-color;
    }
    // Invalid
    .Mui-error {
      &.MuiOutlinedInput-root {
        &.Mui-focused fieldset {
          border-style: solid;
          border-color: $input-error-border-color !important;
        }
        [data-testid="CloseIcon"]{
          display:none;
        }
      }

      label.Mui-focused {
        color: $primary-color;
      }

      & + .MuiFormHelperText-contained {
        border-color: $input-error-border-color;
        color: $error-text-color;
        border-style: solid;
        border-width: 1px;
        border-top-width: inherit;
        margin: 0;
        padding: 0.4rem 0.8rem;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background:#FBEBEA
      }
    }
  }
  .MuiInputLabel-root {
    &.MuiFormLabel-filled {
      color: $input-label-color;
    }
  }
}
